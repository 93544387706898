/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react'
import GlobalNavbar from '../components/GlobalNavbar'
import styled from 'styled-components'
import { getToStoreData } from '../plugins/storage';
import { TableContainer, TablePagination, TableSortLabel } from '@mui/material';
import usePagination from '../hooks/usePagination ';
import useSorting from '../hooks/useSorting ';
import { Link } from 'react-router-dom';
import RSVPReminder from './RSVPReminder';

const RSVPList = () => {

  const [RSVPList, setRSVPList] = useState([
    { firstName: "Aheesh", lastName: "Singh", company: "MP Asset Management", phone: "0686777783", location: "Datatec FY24 Final Results Webcast", dPreferences: "none", status: "Yes" },
    { firstName: "Andrew", lastName: "Duys", company: "Umthambo Wealth", phone: "0848100596", location: "Datatec FY24 Final Results Webcast", dPreferences: "none", status: "Yes" },
    { firstName: "Bianca", lastName: "Triebel", company: "Optimum Investment Group", phone: "0764533393", location: "Datatec FY24 Final Results Webcast", dPreferences: "none", status: "Yes" }
  ]);
  const [loggedInMessage, setLoggedInMessage] = useState('');
  const [searchItem, setSearchItem] = useState('');
  const [tabActive, setTabActive] = useState('list');

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination();
  const { order, orderBy, handleRequestSort, sortArray } = useSorting();

  useEffect(() => {
    const selectedGroupData = getToStoreData('selectedGroup');

    if (selectedGroupData) {
      const message = 'You are logged in to Communicator';
      setLoggedInMessage(
        message + ' Editing Group: ' + JSON.parse(selectedGroupData).name
      );
    }
  }, []);

  const RSVPListFilter = useMemo(() => {
    return RSVPList.filter((item) => {
      return (
        item.firstName?.toLowerCase().includes(searchItem.toLowerCase()) ||
        item.lastName.toLowerCase().includes(searchItem.toLowerCase()) ||
        item.company.toLowerCase().includes(searchItem.toLowerCase()) ||
        item.phone.toLowerCase().includes(searchItem.toLowerCase()) ||
        item.location.toLowerCase().includes(searchItem.toLowerCase()) ||
        item.dPreferences.toLowerCase().includes(searchItem.toLowerCase()) ||
        item.status.toLowerCase().includes(searchItem.toLowerCase())
      );
    });
  }, [RSVPList, searchItem])

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleTabActive = (tab) => {
    setTabActive(tab);
  }

  return (
    <Wrapper>
      <GlobalNavbar LoggedIn={loggedInMessage} />
      <div className="container mt-4">
        <div className='mb-3'>
          <h6 className='container fw-bold'>Manage your RSVPs</h6>
          <ul className="nav nav-tabs RSVP-tab border-0 ">
            <li className="nav-item">
              <Link className={`nav-link ${tabActive === "list" ? "active" : ""}`} onClick={() => handleTabActive("list")} to="">List</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${tabActive === "create" ? "active" : ""}`} onClick={() => handleTabActive("create")} to="">Create</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${tabActive === "recipients" ? "active" : ""}`} onClick={() => handleTabActive("recipients")} to="">Recipients</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${tabActive === "send" ? "active" : ""}`} onClick={() => handleTabActive("send")} to="">Send</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${tabActive === "RSVP" ? "active" : ""}`} onClick={() => handleTabActive("RSVP")} to="">RSVP's</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${tabActive === "reminder" ? "active" : ""}`} onClick={() => handleTabActive("reminder")} to="">Reminders</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${tabActive === "hide" ? "active" : ""}`} onClick={() => handleTabActive("hide")} to="">Hide advanced</Link>
            </li>
          </ul>
        </div>
        <hr />
        {tabActive === "list" ? <>
          <div className="d-flex justify-content-between align-items-center flex-wrap mb-4">
            <h4 style={{ color: "#0c3e2b" }} className='mb-0'>RSVP List</h4>
            <input type='text' className='form-control w-auto' placeholder='Search' value={searchItem} onChange={(e) => setSearchItem(e.target.value)} />
          </div>
          <div className='table-responsive accordion-header'>
            <TableContainer>
              <table className="display table table-hover w-100 mb-1 border border-1">
                <thead className="table-header">
                  <tr>
                    <th className="text-start"><TableSortLabel active={orderBy === "firstName"} direction={orderBy === "firstName" ? order : 'asc'} onClick={() => handleRequestSort("firstName")}>First Name</TableSortLabel></th>
                    <th className="text-start"><TableSortLabel active={orderBy === "lastName"} direction={orderBy === "lastName" ? order : 'asc'} onClick={() => handleRequestSort("lastName")}>Last Name</TableSortLabel></th>
                    <th className="text-start"><TableSortLabel active={orderBy === "company"} direction={orderBy === "company" ? order : 'asc'} onClick={() => handleRequestSort("company")}>Company</TableSortLabel></th>
                    <th className="text-start"><TableSortLabel active={orderBy === "phone"} direction={orderBy === "phone" ? order : 'asc'} onClick={() => handleRequestSort("phone")}>Phone</TableSortLabel></th>
                    <th className="text-start"><TableSortLabel active={orderBy === "location"} direction={orderBy === "location" ? order : 'asc'} onClick={() => handleRequestSort("location")}>Location</TableSortLabel></th>
                    <th className="text-start"><TableSortLabel active={orderBy === "dPreferences"} direction={orderBy === "dPreferences" ? order : 'asc'} onClick={() => handleRequestSort("dPreferences")}>Dietary preferences</TableSortLabel></th>
                    <th className="text-start"><TableSortLabel active={orderBy === "status"} direction={orderBy === "status" ? order : 'asc'} onClick={() => handleRequestSort("status")}>Status</TableSortLabel></th>
                  </tr>
                </thead>
                <tbody>
                  {RSVPListFilter.length > 0 ?
                    sortArray(RSVPListFilter, getComparator(order, orderBy))
                      .slice(rowsPerPage * page, rowsPerPage * page + rowsPerPage).map((r, index) => (
                        <tr key={index}>
                          <td className="px-2 py-2">{r.firstName}</td>
                          <td className="px-2 py-2">{r.lastName}</td>
                          <td className="px-2 py-2">{r.company}</td>
                          <td className="px-2 py-2">{r.phone}</td>
                          <td className="px-2 py-2">{r.location}</td>
                          <td className="px-2 py-2">{r.dPreferences}</td>
                          <td className="px-2 py-2">{r.status}</td>
                        </tr>
                      ))
                    : (
                      <tr>
                        <td colSpan={7} className="text-center">No Data Found</td>
                      </tr>
                    )}
                </tbody>
              </table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
              component="div"
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPage={rowsPerPage}
              count={RSVPListFilter.length}
              page={page}
              className="bg-gray-50"
            />
          </div>
        </>
          : tabActive === "reminder" && <RSVPReminder />}
      </div>
    </Wrapper>
  )
}

export default RSVPList;


const Wrapper = styled.div`
  .table-header tr th {background:#ededed;}
  .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows{ margin-bottom: 0px !important;}
  .table > tbody > tr:nth-child(even) > td {background: #dbfef7;}
  .table > tbody > tr > td{color:#424242}
  .MuiTablePagination {color: #016b55;}
  
  /* tab-panel style part */
  .RSVP-tab.nav-tabs .nav-link{ 
    border: none;
    color: #3b4a46;
    font-weight: 600;
    transition: all .3s ease;
  }
  .nav-tabs .nav-link.active{
    /* border-bottom: 2px solid #87baa9; */
    color: #87baa9;
  }
`