import React, { useEffect, useState } from 'react'
import { addToStoreData, removeToStoreData } from '../plugins/storage';
import * as bootstrap from 'bootstrap/dist/js/bootstrap';
import axios from '../plugins/axios';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

const VerifyAuth = () => {

  const [errorMessage, setErrorMessage] = useState("");
  const { token } = useParams();
  const history = useNavigate();

  function verifyAuthToken() {
    removeToStoreData("selectedGroup");
    const authModal = new bootstrap.Modal(document.getElementById('authentic-modal'));
    authModal.show();

    axios.get(`/verify/${token}`).then((e) => {
      addToStoreData('auth-token', token);
      addToStoreData('userInfo', JSON.stringify(e.data.user_data));
      addToStoreData('groupData', JSON.stringify(e.data.user_data.groups_admin));
      authModal.hide();
      history('/communicate');
    }).catch((e) => {
      if (e.response) {
        setErrorMessage(e.response.data.message);
        localStorage.setItem("editor_error", e.response.data.message)
      }
      authModal.hide();
      const errorModal = new bootstrap.Modal(document.getElementById('error-modal'));
      errorModal.show();
      console.log(e)
    });
  }

  function handleSession() {
    axios.get(`/refresh-token/${token}`).then((response) => {
      console.log(response);
      history(`/verify/${response.data.new_token}`);
    }).catch((e) => {
      console.log(e);
    });
  }

  useEffect(() => {
    verifyAuthToken();
  }, [])

  return (
    <Wrapper>
      <div className="modal" id="authentic-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body mb-1 pt-0 pb-4 px-4">
              <div className="d-flex align-items-center gap-2 mb-2">
                <div className="auth-icon">
                  <img src="/images/auth.png" alt="" className="img-fluid" />
                </div>
                <h3 className="mb-0" style={{ color: "#244f41" }}>Authenticating...</h3>
              </div>
              <p className="mb-0">We are authenticating your access to Communications.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="modal" id="error-modal" data-bs-backdrop="static" data-bs-keyboard="false"
        tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body my-1 d-flex gap-2 pt-0 pb-4 px-4">
              <div className="error-icon">
                <img src="/images/error.png" alt="" className="img-fluid" />
              </div>
              <div>
                <h4 className="mb-1">Opps! An error occured!</h4>
                <p className="mb-0">{errorMessage}</p>
                {errorMessage === "Invalid or expired token" ?
                  <p className="mb-0" >Please click <button style={{ color: "#829784;", border: "none", backgroundColor: "unset", borderBottom: "2px solid #4f6d50" }} onClick={handleSession} >here</button> to refresh your session.</p> :
                  <p className="mb-0" >Please click <a href="https://dev.investoreports.com" style={{ color: "#829784", textDecoration: "none", borderBottom: "2px solid #4f6d50" }}>here</a> to go to login page.</p>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
    .modal-header{
        border-bottom: none !important;
    }

    #authentic-modal p{
        font-size: 13px;
        color: #696969;
    }
    #error-modal p{
        font-size: 14px;
        color: #696969;
    }

    #error-modal .modal-header{
        padding-bottom: 5px !important ;
    }

    .auth-icon{
        width: 40px;
        height: auto;
    }

    .error-icon{
        width: 35px;
        height: auto;
    }

    #authentic-modal .modal-dialog{
        max-width: 420px;
      }
    
    @media (max-width: 575px) {
        #authentic-modal .modal-dialog{
            max-width: 100% !important;
        }
    }
`

export default VerifyAuth;
