import React from 'react';
import styled from 'styled-components';

const RSVPReminder = () => {

  return (
    <Wrapper>
      <div className="container mt-5 mb-3">
        <div className="card px-3 py-2 mb-4">
          <h5 className="card-title mb-2">Prompt for RSVP's</h5>
          <h6 className="card-text mb-2">
            This will send the invitation again ONLY to those invitees who have not yet RSVP'd.
          </h6>
          <div className="mb-2">
            <span className="text-muted left-title">Total Reminders Sent: </span>
            <span className="fw-bold text-black small">0</span>
          </div>
          <div className="mb-2">
            <span className="text-muted left-title">Last Reminder sent on: </span>
            <span className="fw-bold text-black small">N/A</span>
          </div>
          <div>
            <button className="btn btn-Vault">Send reminder to RSVP</button>
          </div>
        </div>
        <div className="card px-3 py-2 mb-4">
          <h5 className="card-title mb-2">Remind RSVP's to attend</h5>
          <h6 className="card-text mb-2">
            This will send the invitation again, without RSVP buttons, to those who have RSVP'd.
          </h6>
          <div className="mb-2">
            <span className="text-muted left-title">Total Reminders Sent: </span>
            <span className="fw-bold text-black small">0</span>
          </div>
          <div className="mb-2">
            <span className="text-muted left-title">Last Reminder sent on: </span>
            <span className="fw-bold text-black small">N/A</span>
          </div>
          <div>
            <button className="btn btn-Vault">Send reminder to attend</button>
          </div>
        </div>
      </div>
    </Wrapper >
  )
}

export default RSVPReminder;

const Wrapper = styled.div`
  .btn-reminder{
    background-color: #5885b9;
    color: #fff;
  }

  .card{
    border: unset;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    background-color: #fafafa;
  }

  .card-text{
    font-weight: bold;
    color: #000000;
  }
  .left-title{
    font-weight: 500;
    color: #8f8f8f;
  }
`