import moment from 'moment';
import React from 'react'
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { saveAs } from "file-saver";
import ical from 'ical-generator';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const RSVP = () => {
  let query = useQuery();
  const data = query.get("data") || '';
  const webcastEventToggle = query.get("webcastEventToggle") || "false";

  const handleSave = (c) => {
    const start = c.startDate + " " + c.startTime;
    const end = c.endDate + " " + c.endTime

    // Create a new iCal object
    const calendar = ical();

    // Add event to the calendar
    calendar.createEvent({
      start: start,
      end: end,
      summary: c.title,
      description: c.details
    });

    // Generate the .ICS file content
    const content = calendar.toString();

    const blob = new Blob([content], { type: "text/plain;charset=utf-8" });

    saveAs(blob, "event-schedule.ics");
  };


  const dateFormatChange = (date) => {
    const formattedDate = moment(date).format('YYYYMMDDTHHmmss') + 'Z';

    return formattedDate;
  }

  const body = `Hi,

I receive alerts from the Vault investor platform and thought you might find the service useful.
You can sign up at https://thevault.exchange and join the groups of the companies you follow.

Regards
TheVault`;

  const emailLink = `mailto:athrossell@thevault.exchange?subject=Introduction to the Vault investor alerts service&body=${encodeURIComponent(body)}`;

  return (
    <Wrapper className="container d-flex align-items-center justify-content-center py-4">
      <div className="calendar-section">
        <div className="calendar-head py-2 px-4">
          <div style={{ width: "41px" }}>
            <img src="/images/Icon-black-Vault.svg" alt="logo" className='img-fluid' />
          </div>
          <h5 className='mb-1 heading-title fw-normal'>Invitation</h5>
        </div>
        <h6 className='heading-title pt-3 px-4 mb-0 fw-bold'>TEST--- Datatec Group Annual Results for the year ended 29 February 2024 Webcast Invitation</h6>
        <div className='pb-3 px-4'>
          {data && JSON.parse(data).map((c, index) => {
            return (
              <div className='calendar-info mb-2 mt-4' key={index}>
                <p className='mb-0'>
                  {(c.startTime || c.endTime) &&
                    <span>{c.startTime && moment(c.startTime, "HH:mm").format("hh:mm a")}{c.endTime && " - " + moment(c.endTime, "HH:mm").format("hh:mm a")}</span>}
                  {(c.startDate || c.endDate) &&
                    <span className='ps-1'>{c.startDate && moment(c.startDate).format("Do MMMM YYYY")}{c.endDate && " - " + moment(c.endDate).format("Do MMMM YYYY")}</span>}
                </p>
                {c.title && <h5 className='fw-bold fs-6 mb-0'>{c.title}</h5>}
                {c.details && <p className='mb-0 mt-2 text-muted small'>{c.details}</p>}
                <div className='switch-label mt-2'>
                  <label className={`yes-label ${webcastEventToggle && JSON.parse(webcastEventToggle) ? "active" : ""}`}>Yes</label>
                  <label className={`no-label ${webcastEventToggle && JSON.parse(webcastEventToggle) ? "" : "active"}`}>No</label>
                </div>
                {webcastEventToggle && JSON.parse(webcastEventToggle) && <>
                  <h6 className='pt-2 fw-bold fs-6'>Add to calendar</h6>
                  <span >Location | Webcast:</span>
                  <div className='download-link pt-2'>
                    <button className='me-1' onClick={() => handleSave(c)}>
                      <img src="/images/ics-icon.png" alt="ics-icon" className='img-fluid' width={25} height={25} />
                    </button>|
                    <a target="_blank" rel="noreferrer"
                      href={`https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${dateFormatChange(new Date(c.startDate + " " + c.startTime))}/${dateFormatChange(new Date(c.endDate + " " + c.endTime))}&details=${c.details}&text=${c.title}&reminders=[{"method":"none"}]`} className="text-decoration-none"
                    >
                      {/* <img src="/images/google-calendar.png" alt="google-calendar-icon" className='img-fluid' width={25} height={25} /> */}
                      <span className='ps-1'>Google</span>
                    </a>
                  </div>
                </>}
              </div>)
          })}
          <div>
            <button className="btn btn-Vault px-5 py-1">Save</button>
          </div>
          <div className='calendar-footer py-3'>
            <a href={emailLink}><i className="fa-solid fa-user-group"></i> Would you like to add a colleague to The Vault?</a>
            <a href='mailto:athrossell@thevault.exchange'> <i className="fa-regular fa-envelope"></i> Drop us an email should you need assistance</a>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .calendar-head{
    background-color: #f7f7f7;
    border-radius: 10px 10px 0 0;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .calendar-head p{
    color: #8a959b;
    font-size: 15px;
  }

  .heading-title{
    color: #2f2f2f;
  }

  .calendar-section{
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 500px;
  }

  .calendar-info{
    padding: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #f7f7f7;
  }

  .calendar-info i{
    font-size: 14px;
  }

  .calendar-info span, .calendar-footer p{
    color: #696969;
    font-size: 14px;
  }
  
  .calendar-footer a{
    text-decoration: none;
    /* color: #696969; */
    color: #000000;
    font-size: 15px;
    display: inline-block;
    font-weight: 500;
  }
  
  .download-link, .download-link span{
    font-weight: bold;
    color: #0f80fe;
  }

  .download-link button{
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0px;
  }
  .switch-label label{
    background-color: #d8d8d8;
    padding: 5px 15px;
    cursor: pointer;
  }
  .yes-label{
    border-radius: 3px 0;
  }
  .no-label{
    border-radius: 0 3px;
  }
  .switch-label label.active{
    background-color: #3e6f59;
    color: #ffffff;
  }
`

export default RSVP
