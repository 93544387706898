import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getToStoreData } from '../plugins/storage';
import GlobalNavbar from '../components/GlobalNavbar';
import axios from '../plugins/axios';
import styled from 'styled-components';
import usePagination from '../hooks/usePagination ';
import useSorting from '../hooks/useSorting ';
import { TableContainer, TablePagination, TableSortLabel } from '@mui/material';
import * as bootstrap from 'bootstrap/dist/js/bootstrap';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const UserList = () => {
  const [loggedInMessage, setLoggedInMessage] = useState('');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [userList, setUserList] = useState([]);
  const [userListFilter, setUserListFilter] = useState([]);
  const [groupUserTagList, setGroupUserTagList] = useState([]);
  const [selectGroupTag, setSelectGroupTag] = useState("");
  const [selectGroupTagToggle, setSelectGroupTagToggle] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [modalData, setModalData] = useState("");
  const [recipientList, setRecipientList] = useState([]);

  const templateTitle = useRef(null);

  const [existingUserList, setExistingUserList] = useState([]);
  const [newUserList, setNewUserList] = useState([]);

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination();
  const { order, orderBy, handleRequestSort, getComparator, sortArray } = useSorting();

  const history = useNavigate();
  const [showTooltip, setShowTooltip] = useState(false); // Control tooltip condition
  const buttonRef = useRef(null);

  // Function to handle individual checkbox selection
  const handleCheckboxChange = (id) => {
    setUserList((prevUserList) =>
      prevUserList.map((user) =>
        user.ID === id ? { ...user, isInvite: !user.isInvite } : user
      )
    );
    setExistingUserList((prevUserList) =>
      prevUserList.map((user) =>
        user.ID === id ? { ...user, isInvite: !user.isInvite } : user
      )
    );
  };

  // Function to select all checkboxes
  const handleSelectAll = (event) => {
    const ExistId = userListFilter.map((d) => d.ID);
    setUserList((prevUserList) =>
      prevUserList.map((user) => {
        if (ExistId.includes(user.ID)) {
          return { ...user, isInvite: true }
        }
        return user
      })
    );
    setExistingUserList((prevUserList) =>
      prevUserList.map((user) => {
        if (ExistId.includes(user.ID)) {
          return { ...user, isInvite: true }
        }
        return user
      })
    );
  };

  // Function to clear all checkboxes
  const handleClearAll = (event) => {
    const ExistId = recipientList.map((curElem) => curElem.user_id);
    const userIdList = userList.filter((curElem) => curElem.isInvite).map((u) => u.user_id);

    if (ExistId.length >= userIdList.length) {
      toast.error('You already saved the recipients. Please clear the history before you "Clear it all" again.');
      return;
    }
    setUserList((prevUserList) =>
      prevUserList.map((user) => {
        if (!ExistId.includes(user.ID)) {
          return { ...user, isInvite: false }
        }
        return user;
      })
    );
    setExistingUserList((prevUserList) =>
      prevUserList.map((user) => {
        if (!ExistId.includes(user.ID)) {
          return { ...user, isInvite: false }
        }
        return user;
      })
    );
  };

  const getUserData = (group_id) => {
    axios.get(`/user_list/${group_id || selectedGroup.id}${selectGroupTag ? "/" + selectGroupTag : ""}`).then(async (response) => {

      if (selectGroupTagToggle) {
        const data = response?.data?.flat().map((val) => {
          const usersWithInvite = existingUserList?.find((user) => user.ID === val.ID);

          if (usersWithInvite) {
            return { ...val, isInvite: usersWithInvite.isInvite, email_sent: usersWithInvite.email_sent }
          }
          return val;
        })
        setUserList(data);
      } else {
        setUserList(response?.data?.flat() || []);
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const getGroupUserTag = (group_id) => {
    axios.get(`/group_user_tags/${group_id}`).then(async (response) => {
      setGroupUserTagList(response.data.flat() || []);
    }).catch((error) => {
      console.log(error);
    });
  }

  const GetRecipientData = (group_id) => {

    const userInfoData = getToStoreData('userInfo');
    const communication_uuid = getToStoreData("communication_uuid");

    axios.get(`/user_recipients/${group_id || selectedGroup.id}/${JSON.parse(userInfoData).id}/${communication_uuid}`, {
      headers: {
        'Authorization': `Bearer ${getToStoreData("auth-token")}`
      }
    }).then((response) => {
      if (response.data && response.data?.data?.length > 0) {
        const data = response.data?.data.find((val) => {
          return val.communication_uuid === communication_uuid
        })
        if (data) {
          setRecipientList(JSON.parse(data.user_array));
          setShowTooltip(true);
        } else {
          setRecipientList([]);
          setShowTooltip(false);
        }
      }
    }).catch((error) => {
      console.log(error, "network error");
    })
  }

  // Get selected group name from local storage
  useEffect(() => {
    const selectedGroupData = getToStoreData('selectedGroup');

    const subject = getToStoreData('subject');

    templateTitle.current = subject;

    if (selectedGroupData) {
      const message = 'You are logged in to Communicator';
      setLoggedInMessage(
        message + ' Editing Group: ' + JSON.parse(selectedGroupData).name
      );
      setSelectedGroup(JSON.parse(selectedGroupData));
      getGroupUserTag(JSON.parse(selectedGroupData).id);
      getUserData(JSON.parse(selectedGroupData).id);
      GetRecipientData(JSON.parse(selectedGroupData).id);
    }
  }, []);


  const handleGroupTagChange = (event) => {
    setSelectGroupTagToggle(true);
    setSelectGroupTag(event.target.value);
  }

  useEffect(() => {
    if (selectGroupTagToggle || selectGroupTag) {
      getUserData();
      // GetRecipientData();
    }
  }, [selectGroupTag, selectGroupTagToggle])

  useEffect(() => {
    const list = existingUserList.length !== 0 ? existingUserList : userList;
    const data = list.map((d) => {
      const isCheck = recipientList.find((cur) => {
        return cur.user_id === d.ID
      });

      if (isCheck) {
        return { ...d, isInvite: true, email_sent: isCheck.email_sent }
      } else {
        return { ...d, isInvite: false, email_sent: false }
      }
    })
    if (!selectGroupTagToggle) {
      setUserList(data);
    }
    setExistingUserList(data);
  }, [recipientList]);


  useEffect(() => {
    const data = userList.filter((item) => {
      return (
        item.custom_fields?.["First name"]?.toLowerCase().includes(searchItem.toLowerCase()) ||
        item.custom_fields?.["Last name"]?.toLowerCase().includes(searchItem.toLowerCase()) ||
        item.email_address?.toLowerCase().includes(searchItem.toLowerCase()) ||
        item?.custom_fields?.Company?.toLowerCase().includes(searchItem.toLowerCase()) ||
        item.tags?.toLowerCase().includes(searchItem.toLowerCase())
      );
    });
    setUserListFilter(data);
  }, [userList, searchItem])


  useEffect(() => {
    // const data = existingUserList.filter((d) => d.isInvite);
    setNewUserList(recipientList.map((curElem) => {
      return {
        user_id: curElem.user_id,
        email_address: curElem.email_address,
        email_sent: curElem.email_sent
      }
    }));
    if (recipientList.length < 1) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, [recipientList])

  const handleSaveRecipient = (event) => {
    event.preventDefault();

    const communication_uuid = getToStoreData("communication_uuid");
    const selectGroupData = getToStoreData('selectedGroup');
    const userInfoData = getToStoreData('userInfo');

    const userFilterData = userListFilter.filter((d) => d.isInvite);
    const data = existingUserList.filter((d) => d.isInvite);
    const user_list = data.map((curElem) => {
      return {
        user_id: curElem.ID,
        email_address: curElem.email_address,
        email_sent: curElem.email_sent
      }
    })
    if (userFilterData.length < 1) {
      toast.error("Please select at least one user.")
      return;
    }
    if (recipientCount === 0 && !hasUnsavedChanges && userFilterData.length !== 0) {
      toast.error("You have already sent this email to all these selected recipients.To send again please clear your history.");
      return;
    }
    if (!selectGroupData) return;
    if (!userInfoData) return;
    if (!communication_uuid) return;

    axios.post(`/store_user_lists`, {
      user_array: user_list,
      communication_id: communication_uuid ? communication_uuid : "",
      group_id: JSON.parse(selectGroupData).id.toString(),
      creates_by_user_id: JSON.parse(userInfoData).id.toString()
    }, {
      headers: {
        'Authorization': `Bearer ${getToStoreData("auth-token")}`
      }
    }).then((response) => {
      toast.success("You have saved your list");
      GetRecipientData();
    }).catch((error) => {
      console.log(error, "network error");
      toast.error(error.response ? error.response.data.message : error.message)
    })
  }

  const isAllSelect = useMemo(() => {
    return userListFilter.length > 0 ? userListFilter.every((d) => d.isInvite) : false;
  }, [userListFilter]);

  const ShowSendRecipientModal = () => {
    const userFilterData = userListFilter.filter((d) => d.isInvite);
    const data = existingUserList.filter((d) => d.isInvite);
    const user_list = data.map((curElem) => {
      return curElem.ID
    })
    const new_user_list = recipientList.map((r) => r.user_id)
    const f_user_list = userFilterData.map((curElem) => {
      return curElem.ID
    });

    if (userFilterData.length < 1 && !recipientList.find((v) => f_user_list.includes(v.user_id))) {
      toast.error("Please save your list.")
      return;
    }
    if (!arraysAreEqual(user_list, new_user_list)) {
      toast.error("Selected recipient lists have changed, please save the updated list.");
      return;
    }
    const v = recipientList.find((v) => f_user_list.includes(v.user_id))
    if (recipientCount === 0 && v) {
      toast.error("You have already sent this email to all these selected recipients.To send again please clear your history.");
      return;
    } else {
      const myModal = new bootstrap.Modal(document.getElementById('send-recipient-modal'));
      setModalData(myModal);
      myModal.show();
    }
  }

  const handleSendRecipient = () => {

    const communication_uuid = getToStoreData("communication_uuid");
    const userInfoData = getToStoreData('userInfo');

    if (!communication_uuid) return;
    if (!selectedGroup) return;
    if (!userInfoData) return;

    axios.post(`/send-to-selected-recipients`, {
      communication_uuid,
      group_id: selectedGroup.id,
      user_list_array: newUserList,
      created_by_user_id: JSON.parse(userInfoData).id
    }, {
      headers: {
        'Authorization': `Bearer ${getToStoreData("auth-token")}`
      }
    }).then((response) => {
      GetRecipientData();
      toast.success("Your email has been sent to your selected recipients.");
      modalData.hide();
    }).catch((error) => {
      console.log(error, "network error");
      toast.error(error.response ? error.response.data.message : error.message)
    })
  }

  const handleWarningModalShow = () => {
    if (recipientList.length > 0) {
      const myModal = new bootstrap.Modal(document.getElementById('warning-modal'));
      setModalData(myModal);
      myModal.show();
    } else {
      toast.error("No history found.")
    }
  }

  const warningModalHide = () => {
    modalData.hide();
    setModalData("");
  }

  const handleClearHistory = () => {

    const userInfoData = getToStoreData('userInfo');
    const communication_uuid = getToStoreData("communication_uuid");

    axios.post(`/clear_user_recipients/${selectedGroup.id}/${JSON.parse(userInfoData).id}/${communication_uuid}`, {}, {
      headers: {
        'Authorization': `Bearer ${getToStoreData("auth-token")}`
      }
    }).then((response) => {
      if (response.data) {
        setRecipientList([]);
        setUserList((prevUserList) =>
          prevUserList.map((user) => {
            return { ...user, isInvite: false, email_sent: false }
          })
        );
        setExistingUserList((prevUserList) =>
          prevUserList.map((user) => {
            return { ...user, isInvite: false, email_sent: false }
          })
        );
        toast.success(response.data.message);
        warningModalHide();
      }
    }).catch((error) => {
      console.log(error, "network error");
      toast.error(error.response ? error.response.data.message : error.message);
    })
  }

  const recipientCount = useMemo(() => {
    return newUserList.reduce((total, currentValue) => total + (!currentValue.email_sent ? 1 : 0), 0)
  }, [newUserList]);


  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    return arr1.every((value, index) => {
      return arr2.find((v) => v === value)
    });
  }

  const unSaveChangeWarning = () => {
    const data = existingUserList.filter((d) => d.isInvite);
    const user_list = data.map((curElem) => {
      return curElem.ID
    })
    const new_user_list = newUserList.map((curElem) => {
      return curElem.user_id
    })
    if (!arraysAreEqual(user_list, new_user_list)) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }
    // if (recipientList.length > 0) {
    //   if (newUserList.some((cur) => !user_list?.includes(cur.user_id))) {
    //     setHasUnsavedChanges(true);
    //   } else {
    //     setHasUnsavedChanges(false);
    //   }
    // } else {
    //   newUserList.length < 1 ? setHasUnsavedChanges(false) : setHasUnsavedChanges(true);
    // }
  }

  useEffect(() => {
    unSaveChangeWarning()
  }, [newUserList, existingUserList])

  useEffect(() => {
    const handleTabClose = (event) => {
      if (hasUnsavedChanges) {
        const message = "You are leaving without saving your list. Are you sure?";
        event.preventDefault();
        event.returnValue = message; // For other browsers
        return message; // For older browsers
      }
    };

    window.addEventListener("beforeunload", handleTabClose, { capture: true });

    return () => {
      window.removeEventListener("beforeunload", handleTabClose, { capture: true });
    };
  }, [hasUnsavedChanges]);

  const sendBtnToggle = useMemo(() => {
    const userFilterData = userListFilter.filter((d) => d.isInvite);

    const f_user_list = userFilterData.map((curElem) => {
      return curElem.ID
    });

    return (userFilterData.length < 1 && !recipientList.find((v) => f_user_list.includes(v.user_id))) || showTooltip
  }, [userListFilter, recipientList, showTooltip])

  // Initialize Bootstrap tooltip
  useEffect(() => {
    if (sendBtnToggle && buttonRef.current) {
      const tooltipInstance = new bootstrap.Tooltip(buttonRef.current, {
        title: 'Please save your list',
        trigger: 'hover',
      });
      return () => tooltipInstance.dispose(); // Cleanup tooltip instance
    }
  }, [sendBtnToggle]);

  return (
    <Wrapper>
      <GlobalNavbar LoggedIn={loggedInMessage} />
      <div className="container mt-4">
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-4">
          <h4 style={{ color: "#0c3e2b" }}>Select Recipients and Send </h4>
          <div className="d-flex gap-3 flex-wrap align-items-center">
            <a href='/editor' style={{ textDecoration: "none", color: "#5c7e73" }}>Go Back</a>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 user-filter">
          <div className="d-flex gap-2 flex-wrap mt-2">
            <input type='text' className='form-control' placeholder='Search users' value={searchItem} onChange={(event) => setSearchItem(event.target.value)} />
            <select className='form-select' value={selectGroupTag} onChange={handleGroupTagChange}>
              <option value="" >Select Tags</option>
              {groupUserTagList.map((tag) => (
                <option value={tag.id} key={tag.id}>{tag.tag_name}</option>
              ))}
            </select>
            <button className="btn btn-primary btn-Vault" onClick={handleSaveRecipient}>Save</button>
            <span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" data-bs-title="Disabled tooltip" ref={buttonRef} style={{ cursor: showTooltip ? "not-allowed" : "pointer" }}>
              <button
                className="btn btn-Vault btn-clear-all"
                type="button" disabled={sendBtnToggle} onClick={ShowSendRecipientModal}
              >
                Send Check
              </button>
            </span>
          </div>
          <div className='d-flex gap-2 mt-2 flex-wrap'>
            <button className="btn btn-clear-all" onClick={handleClearAll}>Clear All</button>
            <button className="btn btn-Vault btn-clear-all" onClick={handleWarningModalShow}>Clear history</button>
          </div>
        </div>
        <div className='table-responsive accordion-header'>
          <TableContainer>
            <table className="display table table-hover w-100 mb-1 border border-1">
              <thead className="table-header">
                <tr>
                  <th className="text-start"><TableSortLabel active={orderBy === "firstName"} direction={orderBy === "firstName" ? order : 'asc'} onClick={() => handleRequestSort("firstName")}>First Name</TableSortLabel></th>
                  <th className="text-start"><TableSortLabel active={orderBy === "lastName"} direction={orderBy === "lastName" ? order : 'asc'} onClick={() => handleRequestSort("lastName")}>Last Name</TableSortLabel></th>
                  <th className="text-start"><TableSortLabel active={orderBy === "email_address"} direction={orderBy === "email_address" ? order : 'asc'} onClick={() => handleRequestSort("email_address")}>Email</TableSortLabel></th>
                  <th className="text-start"><TableSortLabel active={orderBy === "company"} direction={orderBy === "company" ? order : 'asc'} onClick={() => handleRequestSort("company")}>Company</TableSortLabel></th>
                  <th className="text-start" style={{ whiteSpace: 'nowrap' }}><TableSortLabel active={orderBy === "tags"} direction={orderBy === "tags" ? order : 'asc'} onClick={() => handleRequestSort("tags")}>Tags</TableSortLabel></th>
                  <th>
                    <div className='d-flex align-items-center gap-1'>
                      <input className="form-check-input mt-0" style={{ cursor: "pointer" }} type="checkbox" checked={isAllSelect} onChange={handleSelectAll} />
                      <span>All</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {userListFilter.length > 0 ?
                  sortArray(userListFilter, getComparator(order, orderBy))
                    .slice(rowsPerPage * page, rowsPerPage * page + rowsPerPage).map((user) => (
                      <tr key={user.ID} className={user.isInvite ? 'highlight' : ''}>
                        <td className="px-2 py-2">{user?.custom_fields?.["First name"]}</td>
                        <td className="px-2 py-2">{user?.custom_fields?.["Last name"]}</td>
                        <td className="px-2 py-2">{user.email_address}</td>
                        <td className="px-2 py-2">{user?.custom_fields?.Company}</td>
                        <td className="px-2 py-2">{user.tags ? user.tags : <i className="fa-solid fa-minus"></i>}</td>
                        <td><input className="form-check-input" type="checkbox" id={user.ID} checked={user.isInvite || false} onChange={() => handleCheckboxChange(user.ID)} /></td>
                      </tr>
                    ))
                  : (
                    <tr>
                      <td colSpan={6} className="text-center">No Data Found</td>
                    </tr>
                  )}
              </tbody>
            </table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
            component="div"
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            count={userListFilter.length}
            page={page}
            className="bg-gray-50"
          />
        </div>
      </div>
      {/* === Send Recipient Modal === */}
      <div className="modal fade" id="send-recipient-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Sending to recipients</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body my-1">
              <p className="text-label">You are sending <strong>{templateTitle.current}</strong> to these <strong>{recipientCount}</strong> recipients. You have already sent this email to <strong>{newUserList.length - recipientCount}</strong> recipients. - all good?</p>
              <hr />
              <div className="mt-3 d-flex gap-3 justify-content-end">
                <button type="submit" className="btn btn-Vault" onClick={handleSendRecipient}>Yes, Send</button>
                <button className="btn btn-primary btn-gray" type="button" style={{ marginRight: "10px" }} onClick={() => {
                  modalData.hide();
                  setModalData("")
                }}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Clear History Waring Modal */}
      <div className="modal fade" id="warning-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Clear recipients history</h4>
              <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div className="modal-body px-4 py-2 d-flex gap-4">
              <div className="check-icon">
                <img src="/images/Trash-list-account-icon.png" alt="check_icon" className="img-fluid" />
              </div>
              <div className='mt-3 content-right'>
                <p className="text-black">Clearing your history will permanently delete all saved recipients and remove the status of any previously sent invites.</p>
                <h6>This action cannot be undone.</h6>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn clear-history-cancel" onClick={warningModalHide}>Cancel</button>
              <button type="button" className="btn clear-history-confirm px-4" onClick={handleClearHistory}>Confirm</button>
            </div>
          </div>
        </div>
      </div >
    </Wrapper>
  );
};

export default UserList;

const Wrapper = styled.div`
  .table-header tr th {background:#ededed;}
  .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows{ margin-bottom: 0px !important;}
  .form-check-input:checked {background-color: #92edce;border-color: #92edce;}
  .form-check-input{margin-top:10px;cursor: pointer;}
  .form-check-input:focus,.form-check-input:active{box-shadow:unset;filter:unset;}
  .btn-Vault{
    font-weight: 400 !important;
    letter-spacing: 1px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .btn-Vault-secondary{
    background: none;
    border: 1px solid #92edce;
    color: #000;
  }

  .btn-Vault-secondary:hover{
    background: #013321;
    border: 1px solid #013321;
    color: #fff !important;
  }

  .btn-clear{
    background: #ffffff !important;
    color: #c1c1c1 !important;
    border: none;
  }

  .btn-clear-all{
    background-color: #ffffff !important;
    border: none !important;
    color: #000 !important;
  }

  .form-select, .form-control{
    width: 30%;
  }

  .user-filter{
    background: #f3f3f3;
    padding: 23px 10px;
  }
  .user-filter .form-control, .user-filter .form-select {
    border:none;
    /* background: #ffffff; */
  }
  .table > tbody > tr:nth-child(even) > td {background: #dbfef7;}
  .table > tbody > tr > td{color:#424242}
  .MuiTablePagination {color: #016b55;}

  .text-label{
    margin-bottom: 0;
    font-size: 13px;
    background: #92edce;
    padding: 10px 5px 10px 19px;
    border-radius: 5px;
    color: #000000;
  }

  #warning-modal .fa-check{
    font-size: 28px;
    font-weight: bolder;
  }

  #warning-modal .modal-title{
    color: #265344 !important;
    font-size: 17px;
  }

  #warning-modal p{
    font-size: 14px;
    color: #696969;
    /* color: #c5c5c5; */
  }

  #warning-modal .check-icon{
    width: 70px;
    height: auto;
  }
  #warning-modal .content-right{width: calc(100% - 80px)}

  #warning-modal .modal-dialog{
    max-width: 500px;
  }

  @media (max-width: 575px) {
    #warning-modal .modal-dialog{
      max-width: 100% !important;
    }
  }
  #warning-modal .modal-body{
    /* flex-direction: column; */
    justify-content: start;
    align-items: center;
    gap: 15px;
    padding-top: 0;
  }

  .clear-history-cancel{
    background: #eaeaea;
    color: #000;
    border: none;
  }
  
  .clear-history-confirm{
    background: #fe0000;
    color: #fff;
    border: none;
  }

  @media (max-width: 375px) {
    #warning-modal p{
      font-size: 13px;
    }
  }

  .bg-warning {
    background-color: #ffc107;
    color: #212529;
  }


  @media (max-width: 480px) {
    .form-select, .form-control{
      width: 100% ;
    }
  }
`
