import React, { useEffect, useState } from 'react'
import GlobalNavbar from '../components/GlobalNavbar'
import { addToStoreData, getToStoreData } from '../plugins/storage';
import axios from '../plugins/axios';
import { useNavigate } from 'react-router-dom';
import mjml from "mjml-browser";
import MyEditor from '../components/emailEditor/MyEasyEditor';
import toast from 'react-hot-toast';

const TinyMice = () => {

  const [loggedInMessage, setLoggedInMessage] = useState("You are logged in to Communicator");
  const [editor, setEditor] = useState({
    json: {},
    mjml: '',
    html: ""
  });
  const history = useNavigate();

  useEffect(() => {
    const selectGroupData = getToStoreData('selectedGroup');

    if (!selectGroupData) return;

    setLoggedInMessage(loggedInMessage + ' Editing Group: ' + JSON.parse(selectGroupData).name);
  }, []);

  const handleSaveForm = () => {
    console.log("Save button clicked in Editor vue");
    console.log(JSON.stringify(editor.json));
    console.log(getToStoreData('selectedGroup'), 'Group ID');
    console.log(editor.mjml);
    console.log(editor.html);
  }

  const saveDesign = (status) => {
    let subject = getToStoreData("subject");

    if (!subject) return;

    const selectGroup = getToStoreData('selectedGroup');
    const userInfo = getToStoreData('userInfo');
    const communication_uuid = getToStoreData("communication_uuid");

    if (!selectGroup || !userInfo) return;

    const userId = JSON.parse(userInfo).id;
    const groupId = JSON.parse(selectGroup).id;

    let URL = ""

    if (communication_uuid) {
      URL = axios.post("/update-communication", {
        title: subject,
        description: subject,
        email_body: editor.html,
        email_body_json: JSON.stringify(editor.json),
        group_id: groupId,
        user_id: userId,
        uuid: communication_uuid
      });
    } else {
      URL = axios.post("/communication", {
        title: subject,
        description: subject,
        email_body: editor.html,
        email_body_json: JSON.stringify(editor.json),
        group_id: groupId,
        user_id: userId
      });
    }

    URL.then((response) => {
      if (response.data.communications_uuid) {
        addToStoreData("communication_uuid", response.data.communications_uuid)
      }
      if (!status) {
        history('/communicate');
      }
      if(status === "save"){
        toast.success("Your template has been saved successfully.")
      }
    }).catch((error) => {
      console.log(error, "network error");
    })
  }

  const handleEditorSubmit = (formData) => {
    if (formData.json !== editor.json) {
      setEditor({ ...formData, html: mjml(formData.mjml).html });
    }
  }

  useEffect(() => {
    if (editor.html) {
      const timer = setTimeout(() => {
        saveDesign("auto");
      }, 3000);
  
      return () => clearTimeout(timer);
    }
  }, [editor])

  return (
    <div id="app">
      <GlobalNavbar LoggedIn={loggedInMessage} saveForm={handleSaveForm} saveDesign={saveDesign} editor={editor} />
      <MyEditor onSubmit={handleEditorSubmit} />
    </div>
  )
}

export default TinyMice