import React, { useEffect, useState } from 'react';
import * as bootstrap from 'bootstrap/dist/js/bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { addToStoreData, clearToStoreData, getToStoreData } from '../plugins/storage';
import axios from '../plugins/axios';
import styled from 'styled-components';
import toast from 'react-hot-toast';

const GlobalNavbar = ({ editor, LoggedIn, saveDesign }) => {

  const [subject, setSubject] = useState("Add your subject line here");
  const [subHeader, setSubHeader] = useState(false);
  const [modalData, setModalData] = useState("");
  const [groupAdmin, setGroupAdmin] = useState([]);
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [selectGroup, setSelectGroup] = useState({});
  // const [userInfo, setUserInfo] = useState({});

  const { pathname } = useLocation();
  const history = useNavigate();

  useEffect(() => {
    if (pathname === '/editor') {
      setSubHeader(true);
    }

    const communicate_data = getToStoreData("communicate_data");
    if (communicate_data) {
      const data = JSON.parse(communicate_data);
      setSubject(data.title);
    }
  }, [pathname]);

  // Store the subject in storage.
  useEffect(() => {
    if (subject) {
      addToStoreData("subject", subject);
    }
  }, [subject])

  const handlePreview = () => {
    const previewIcon = document.querySelector(".arco-icon-eye");
    const parentElement = previewIcon.parentNode;
    parentElement && parentElement.click();
    console.log("Preview button clicked");
    // Perform action for Preview button
  }

  const showModal = () => {
    setEmail("");
    const selectGroupData = getToStoreData('selectedGroup');

    if (!selectGroupData) return;

    setSelectGroup(JSON.parse(selectGroupData));

    axios.get(`/group-admins/${JSON.parse(selectGroupData).id}`, {
      headers: {
        'Authorization': `Bearer ${getToStoreData("auth-token")}`
      }
    }).then((response) => {
      if (response.data.length !== 0) {
        setGroupAdmin(response.data.flat());
      }
      const myModal = new bootstrap.Modal(document.getElementById('send-modal'));
      setModalData(myModal);
      myModal.show();
    }).catch((error) => {
      console.log(error, "network error");
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const selected_admin_emails = groupAdmin.filter((user) => {
      return user.user_status;
    }).map((data) => data.user_email);

    const communication_uuid = getToStoreData("communication_uuid");
    console.log(editor.html)
    if (!email) return;

    axios.post(`/send-test-email`, {
      body: editor.html,
      email: email,
      communication_uuid,
      group_id: selectGroup.id,
      selected_admin_emails: selected_admin_emails,
      name: subject,
      subject: subject
    }, {
      headers: {
        'Authorization': `Bearer ${getToStoreData("auth-token")}`
      }
    }).then((response) => {
      modalData.hide();
      setEmail("");
      setSuccessMessage("Test email has been successfully sent");
      const successModal = new bootstrap.Modal(document.getElementById('success-modal'));
      successModal.show();
    }).catch((error) => {
      console.log(error, "network error");
      toast.error(error.response ? error.response.data.message : error.message)
    })
  }

  const sendToAllModal = () => {
    const sendToAllModalReference = new bootstrap.Modal(document.getElementById('send-to-all-modal'));
    setModalData(sendToAllModalReference);
    sendToAllModalReference.show();
  }

  const closeModal = () => {
    modalData.toggle();
  }

  const sendToAll = () => {
    const communication_uuid = getToStoreData("communication_uuid");
    const selectGroupData = getToStoreData('selectedGroup');

    if (!selectGroupData) return;

    setSelectGroup(JSON.parse(selectGroupData));
    axios.post('/send-to-all-emails', {
      body: editor.html,
      communication_uuid,
      group_id: JSON.parse(selectGroupData).id,
      name: subject,
      subject: subject
    }, {
      headers: {
        'Authorization': `Bearer ${getToStoreData("auth-token")}`
      }
    }).then((response) => {
      modalData.toggle();
      setSuccessMessage("Your email has been successfully sent");
      const successModalReference = new bootstrap.Modal(document.getElementById('success-modal'));
      successModalReference.show();
    }).catch((error) => {
      toast.error(error.response ? error.response.data.message : error.message);
    })
  }

  const handleLogout = () => {
    clearToStoreData();
    history("/unauthorized");
  }

  const handleHomeBack = () => {
    history("/communicate");
  }

  const subjectChange = (event) => {
    setSubject(event.target.value);
  }

  // Handle checkbox change
  const handleCheckboxChange = (id) => {
    setGroupAdmin((prevGroupAdmin) =>
      prevGroupAdmin.map((data) =>
        data.ID === id ? { ...data, user_status: !data.user_status } : data
      )
    );
  };

  const handleRedirectUserList = () => {
    const data = {
      title: subject,
      email_body_json: JSON.stringify(editor.json),
      email_body: JSON.stringify(editor.html)
    }
    addToStoreData("communicate_data", JSON.stringify(data));
    history("/user-list");
  }

  return (
    <Wrapper>
      <nav className="navbar navbar-light bg-light">
        <div className="container-fluid d-flex justify-content-between align-items-center">
          {/* <!-- Logo and Description on the left --> */}
          <Link className="navbar-brand" onClick={handleHomeBack} to="#">
            <img src="/images/Icon-black-Vault.svg" alt="Logo" width="35" height="35" className="d-inline-block align-top mr-2" />
            <small className="text-muted ml-2">
              {LoggedIn && <div className="logged-in-message">{LoggedIn}</div>}
            </small>
          </Link>

          {/* <!-- Buttons on the right --> */}
          <div className="d-flex align-items-center">
            <button className="btn btn-link logoutBtn" type="button" onClick={handleLogout}>
              <img src="/images/logout.png" style={{ width: "25px" }} alt='logout_icon' />
            </button>
          </div>
        </div>
      </nav>
      {subHeader && LoggedIn &&
        <div className="row navBuilder logged-in-message container-fluid">
          <div className="col-md-6">
            <form>
              <div className="input-group">
                <span className="template-subject">Subject</span>
                <input type="text" name="subject" placeholder="Enter email subject..." className="form-control titleInput" onChange={subjectChange} value={subject} />
              </div>
            </form>
          </div>
          <div className="col-md-6">
            <div className="logged-in-message btn-right">
              <button className="btn btn-primary btn-gray" type="button" style={{ marginRight: "10px" }} onClick={handlePreview}>Preview</button>
              <div className="btn-group">
                <button className="btn btn-secondary btn-Vault dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  File
                </button>
                <ul className="dropdown-menu">
                  {/* This will be a save button to save the design all design getting saved not as a draft */}
                  <li onClick={() => saveDesign("save")}><Link to="" className="dropdown-item">Save</Link></li>
                  <li><hr className="dropdown-divider" /></li>
                  {/* This will be a save button to save the design all design getting saved as a draft */}
                  <li onClick={() => saveDesign()}><Link to="" className="dropdown-item">Save and exit</Link></li>
                </ul>
              </div>

              <div className="btn-group">
                <button className="btn btn-secondary btn-Vault dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Send
                </button>
                <ul className="dropdown-menu">
                  {/* Please review I might have affected the Css for button didnt change much */}
                  {/* <li className="btn-Vault-sendTest" style={{ marginRight: "10px" }} id="save" type="button" onClick={showModal}>Send test</> */}
                  <li id="save" onClick={showModal}><Link to="" className="dropdown-item">Send test</Link></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li onClick={sendToAllModal}><Link to="" className="dropdown-item">Send to all</Link></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li onClick={handleRedirectUserList}><Link to="" className="dropdown-item">Select recipients</Link></li>
                </ul>
              </div>
              <div className="btn-group">
                <button className="btn btn-secondary btn-Vault dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  RSVP's
                </button>
                <ul className="dropdown-menu">
                  <li><Link to="/RSVP-list" className="dropdown-item">View RSVP's</Link></li>
                  {/* <li><hr className="dropdown-divider" /></li>
                  <li><Link to="/RSVP-reminder" className="dropdown-item">Reminders</Link></li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>}
      {/* <!-- send modal --> */}
      <div className="modal fade" id="send-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title fs-5" id="staticBackdropLabel">Send a test email</h3>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body my-1">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Send a test to</label>
                  <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <p className="note">Use commas to separate multiple emails or select a checkbox for each user from your database below.</p>
                <hr />
                {groupAdmin.map((data) => (
                  <div className="form-check" key={data.ID}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={data.user_status}
                      id={data.ID}
                      onChange={() => handleCheckboxChange(data.ID)}
                    />
                    <label className="form-check-label" htmlFor={data.ID}>
                      {data.display_name}
                    </label>
                  </div>
                ))}
                <div className="mt-3 text-end">
                  <button type="submit" className="btn btn-primary">Send test</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div >
      {/* <!-- send To all --> */}
      <div className="modal fade" id="send-to-all-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Send to all</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body my-1">
              <p className="text-label">You are sending an email to all members of this group. All good?</p>
              <hr />
              <div className="mt-3 d-flex gap-3 justify-content-end">
                <button type="submit" className="btn btn-Vault" onClick={sendToAll}>Send to all</button>
                <button className="btn btn-primary btn-gray" type="button" style={{ marginRight: "10px" }} onClick={closeModal}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div >
      {/* // <!-- success modal --> */}
      <div className="modal fade" id="success-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body my-1 d-flex gap-2">
              <div className="check-icon">
                <img src="/images/success-1.png" alt="check_icon" className="img-fluid" />
              </div>
              <div>
                <h5 id='success-message'>{successMessage}</h5>
                <p className="mb-0">Please give it a few seconds to reach your inbox.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper >
  )
}

const Wrapper = styled.div`
  .dropdown-divider{
    margin: 0 !important;
  }

  .dropdown-menu{
    padding: 0 0 !important;
  }

  .dropdown-item.active, .dropdown-item:active{
    background-color: #565e64 !important;
  }

  .modal-header{
    padding: 0.8rem 1rem 0.3rem !important;
  }

  .note{
    padding: 10px 0 3px !important;
    margin-bottom: 0 !important;
    font-size: 14px;
    color: #c5c5c5;
  }

  .form-control:focus {
    box-shadow: unset !important;
  }

  .form-check-input:focus {
    box-shadow: unset !important;
  }

  #success-modal .modal-header{
    border-bottom: none !important;
  }

  #success-modal .fa-check{
    font-size: 28px;
    font-weight: bolder;
  }

  #success-modal p{
    font-size: 12px;
    color: #696969;
    /* color: #c5c5c5; */
  }

  #success-modal .check-icon{
    width: 100px;
    height: auto;
  }

  #success-modal .check-icon img{
    transform: rotate(5deg);
  }

  #success-modal .modal-dialog{
    max-width: 440px;
  }

  .text-label{
    margin-bottom: 0;
    font-size: 12px;
    background: #92edce;
    padding: 10px 5px 10px 19px;
    border-radius: 5px;
    color: #000000;
  }

  @media (max-width: 575px) {
    #success-modal .modal-dialog{
      max-width: 100% !important;
    }
  }
  #success-modal .modal-body{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 15px;
    padding-top: 0;
  }
  @media (max-width: 375px) {
    #success-message{
      font-size: 16px;
    }
    #success-modal p{
      font-size: 10px;
    }
  }
`

export default GlobalNavbar
