import { useCallback } from "react";
import { cloneDeep, uniqueId } from "lodash";
import React from "react";
import { Node } from "slate";
import { ReactEditor, useSlate } from "slate-react";
import { useEditorState } from "easy-email-pro-editor";
import { AssetManager } from "easy-email-pro-asset-manager";
import "easy-email-pro-asset-manager/lib/style.css";
import { NodeUtils } from "easy-email-pro-core";
import { useUpload } from "./useUpload";
import { getToStoreData } from "../../plugins/storage";

const assetMap = new Map();

export const AssetManagerModal = ({ visible, setVisible, onSelect, accept }) => {

  const { upload } = useUpload();

  const editor = useSlate();
  const { selectedNodePath } = useEditorState();

  const onCreateFile = useCallback(
    async (item) => {
      const newItem = {
        id: "new" + uniqueId(),
        type: "FILE",
        ...item,
        thumbnail: item.url + `?w=200`, // as thumbnail
      };

      const parent = assetMap.get(item.parentFolderId || "root");
      if (parent) {
        parent.push(newItem);
        assetMap.set(item.parentFolderId || "root", [...parent]);
        assetMap.set(item.parentFolderId || "root", [...parent]);
      }

      return newItem;
    },
    []
  );

  const onUpdateFile = useCallback(
    async (item) => {
      const newFile = {
        ...item,
        thumbnail: item.url + `?w=200`, // as thumbnail
      };
      const parent = assetMap.get(item.parentFolderId || "root");
      parent?.forEach((item, index) => {
        if (item.id === newFile.id) {
          parent[index] = newFile;
          assetMap.set(item.parentFolderId || "root", [...parent]);
        }
      });
      return newFile;
    },
    []
  );

  const onDeleteFile = useCallback(
    async (item) => {
      const parent = assetMap.get("root");

      const currentImage = parent.find((curElem) => curElem.id === item.id);

      const formData = new FormData();
      formData.append('image_url', currentImage.url);

      try {
        const response = await fetch(`https://test2.investoreports.com/api/delete-image`, {
          method: 'POST',
          body: formData
        });

        if (response.ok) {
          const result = await response.json();
          console.log(result)
          const imageFilter = parent.filter((curElem) => {
            return curElem.id !== item.id;
          });
          assetMap.set("root", [...imageFilter]);
        }
        return true;
      } catch (error) {
        console.log('Network error:', error);
      }
    },
    []
  );

  const fetchData = useCallback(async (folderId) => {
    // await new Promise((resolve) =>
    //   setTimeout(() => {
    //     resolve(true);
    //   }, 1000)
    // );

    // if (assetMap.get(folderId || "root")) {
    //   return cloneDeep(assetMap.get(folderId || "root"));
    // }

    const GroupData = JSON.parse(getToStoreData("selectedGroup"));
    const config = {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${getToStoreData("auth-token")}`
      }
    };
    const resData = [];
    try {
      const response = await fetch(`https://test2.investoreports.com/api/get-images/${GroupData.id}`, config);

      const result = await response.json();
      if (result.success) {
        result.files.forEach((file, index) => {
          resData.push({
            id: uniqueId(),
            url: file,
            thumbnail: file,
            type: "FILE",
            name: `image${folderId ? `-${index + 1}` : `-${index + 1}`} `,
            parentFolderId: null,
          })
        })
      }
    } catch (error) {
      console.log('Network error:', error);
    }

    assetMap.set(folderId || "root", resData);
    return cloneDeep(resData);
  }, []);

  const onSelectFile = useCallback(
    (url) => {
      if (
        ["png", "jpg", "jpeg", "gif"].some((item) =>
          url.replace(/\?.*/, "").endsWith(item)
        )
      ) {
        const node = Node.get(editor, selectedNodePath);
        const domeNode = ReactEditor.toDOMNode(editor, node);
        if (NodeUtils.isImageElement(node)) {
          const width = domeNode.getBoundingClientRect().width;
          onSelect(url + `?w=${width}`);
        } else {
          onSelect(url);
        }
      } else {
        onSelect(url);
      }
    },
    [editor, onSelect, selectedNodePath]
  );

  return (
    <React.Fragment>
      <AssetManager
        accept={accept}
        showUnacceptedFile
        setVisible={setVisible}
        visible={visible}
        request={fetchData}
        onSelect={onSelectFile}
        upload={upload}
        onCreateFile={onCreateFile}
        onUpdateFile={onUpdateFile}
        onDeleteFile={onDeleteFile}
      />
    </React.Fragment>
  );
};
